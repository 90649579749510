import React, { useEffect, useState } from 'react';
import { Spin, message, Form, Input, Switch } from 'antd';
import _ from 'lodash';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import { API } from '../../config/api';
import { DataService } from '../../config/dataService/dataService';

const Configuration = () => {
  // eslint-disable-next-line
  const [state, setState] = useState({
    loading: true,
    showModal: false,
    corte: null,
    configuration: null,
    printActive: false,
    printerId: '',
    loadingSend: false,
  });

  const [form] = Form.useForm();

  const { loading } = state;

  const fetchConfig = async () => {
    setState({
      ...state,
      loading: true,
    });
    DataService.get(`${API.pos.config}/`)
      .then(payload => {
        const configurationPayload = _.get(payload, 'data.results[0]', { printActive: false });

        setState({ ...state, loading: false, ...configurationPayload });
      })
      .catch(err => {
        setState({ ...state, loading: false });
        // message.error(err?.message || 'Error al intentar actualizar la orden');
      });
  };

  const handleOkDataUpdate = submitForm => {
    setState({ ...state, loading: true });
    const payloadToSend = submitForm;

    DataService.patch(`${API.pos.config}/`, payloadToSend)
      .then(payload => {
        setState({
          ...state,
          loading: false,
          configuration: payload?.data,
        });
        message.success('Configuración actualizada');
      })
      .catch(err => {
        setState({ ...state, loading: false });
        // message.error(err?.message || 'Error al intentar actualizar la orden');
      });
  };

  useEffect(() => {
    fetchConfig().catch(console.log);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Main>
        <PageHeader ghost title="Configuration" buttons={[]} />
        <Cards>
          {loading ? (
            <Spin />
          ) : (
            <Form form={form} name="updateData" onFinish={handleOkDataUpdate}>
              <Form.Item label="Impresion Activa" name="printActive">
                <Switch
                  onChange={value => {
                    form.setFieldsValue(value);
                    setState({ ...state, printActive: value });
                  }}
                  defaultChecked={state.printActive}
                />
              </Form.Item>
              <Form.Item
                label="Print Note - PrinterId"
                name="printerId"
                rules={[{ required: false, message: 'Ingresa un id de impresora valido' }]}
              >
                <div>
                  <Input
                    placeholder="123456"
                    defaultValue={state.printerId}
                    onChange={value => {
                      console.log(value.target.value);
                      return setState({ ...state, printerId: value.target.value });
                    }}
                    value={state.printerId}
                  />
                </div>
              </Form.Item>
              <Button htmlType="submit" size="default" type="primary" key="submit">
                Guardar
              </Button>
            </Form>
          )}
        </Cards>
      </Main>
    </>
  );
};

export default Configuration;
