import _ from 'lodash';
import { getProductString, calculateOptions } from './utility';
import StarWebPrintTrader from '../libs/StarWebPrintTrader';
import StarWebPrintBuilder from '../libs/StarWebPrintBuilder';

let request = '';

const MAX_CHAR_LENGTH = 28;

export const sendMessageToPrinter = (request, ipName = '127.0.0.1') => {
  const url = `https://${ipName}/StarWebPRNT/SendMessage`;
  const papertype = 'normal';

  const trader = new StarWebPrintTrader({ url, papertype });

  trader.onReceive = function(response) {
    // hideNowPrinting();

    let msg = '- onReceive -\n\n';

    msg += `TraderSuccess : [ ${response.traderSuccess} ]\n`;

    //      msg += 'TraderCode : [ ' + response.traderCode + ' ]\n';

    msg += `TraderStatus : [ ${response.traderStatus},\n`;

    if (trader.isCoverOpen({ traderStatus: response.traderStatus })) {
      msg += '\tCoverOpen,\n';
    }
    if (trader.isOffLine({ traderStatus: response.traderStatus })) {
      msg += '\tOffLine,\n';
    }
    if (trader.isCompulsionSwitchClose({ traderStatus: response.traderStatus })) {
      msg += '\tCompulsionSwitchClose,\n';
    }
    if (trader.isEtbCommandExecute({ traderStatus: response.traderStatus })) {
      msg += '\tEtbCommandExecute,\n';
    }
    if (trader.isHighTemperatureStop({ traderStatus: response.traderStatus })) {
      msg += '\tHighTemperatureStop,\n';
    }
    if (trader.isNonRecoverableError({ traderStatus: response.traderStatus })) {
      msg += '\tNonRecoverableError,\n';
    }
    if (trader.isAutoCutterError({ traderStatus: response.traderStatus })) {
      msg += '\tAutoCutterError,\n';
    }
    if (trader.isBlackMarkError({ traderStatus: response.traderStatus })) {
      msg += '\tBlackMarkError,\n';
    }
    if (trader.isPaperEnd({ traderStatus: response.traderStatus })) {
      msg += '\tPaperEnd,\n';
    }
    if (trader.isPaperNearEnd({ traderStatus: response.traderStatus })) {
      msg += '\tPaperNearEnd,\n';
    }

    msg += `\tEtbCounter = ${trader.extractionEtbCounter({ traderStatus: response.traderStatus }).toString()} ]\n`;

    console.log(msg);
  };

  trader.onError = function(response) {
    let msg = '- onError -\n\n';

    msg += `\tStatus:${response.status}\n`;

    msg += `\tResponseText:${response.responseText}\n\n`;

    msg += 'Do you want to retry?\n';

    const answer = !!msg;

    if (answer) {
      sendMessageToPrinter(request, ipName);
    } else {
      // hideNowPrinting();
    }
  };

  trader.sendMessage({ request });
};
const formatIntToMoney = amount => {
  return `$${(Math.round(amount * 100) / 100).toFixed(2)}`;
};

const getCharStringFromNum = (n = 0, char = ' ') => {
  let spaces = '';
  for (let i = 0; i < n; i++) {
    spaces += char;
  }
  return spaces;
};

export const printOrder = (order, ipName) => {
  const builder = new StarWebPrintBuilder();

  const defaultFormat = {
    emphasis: false,
    characterspace: 1,
    font: 'font_a',
    invert: false,
    linespace: 24,
    underline: false,
    width: 1,
    height: 1,
  };
  request = '';

  request += builder.createInitializationElement();

  // Logo
  // request += builder.createBitImageElement({ context: canvasContext, x: 0, y: 0, width: 384, height: 213 });
  request += builder.createAlignmentElement({ position: 'center' });
  request += builder.createLogoElement({ number: 1 });
  request += builder.createAlignmentElement({ position: 'left' });

  /// ///////Cabecera INICIO ////////////
  request += builder.createTextElement({ ...defaultFormat, data: '\n' });
  // Usuario
  request += builder.createTextElement({ data: 'Usuario: ', characterspace: 1, font: 'font_a' });
  request += builder.createTextElement({ data: order.createdBy.name, emphasis: true });
  request += builder.createTextElement({ ...defaultFormat, data: '\n' });
  request += builder.createTextElement({ ...defaultFormat, data: '\n' });
  // Cliente
  request += builder.createTextElement({ data: 'Cliente: ' });
  request += builder.createTextElement({ data: order.customerName, emphasis: true });

  request += builder.createTextElement({ ...defaultFormat, data: '\n' });

  /// //////Cabecera FIN////////////

  /// /////// PRODUCTOS ///////////
  order.products.forEach(productObject => {
    const name = _.get(productObject, 'productId.name', '');

    const optionsSelected = _.get(productObject, 'options', []);
    const optionsCounter = _.get(productObject, 'optionsCounter', []);
    const productOptions = _.get(productObject, 'productId.options', []);
    const extraPrice = calculateOptions(optionsSelected, optionsCounter, productOptions) || 0;
    const productSubTotal = (productObject?.productId?.price || 0) + (extraPrice || 0);
    const productTotal = (productObject?.productId?.price || 0) + (extraPrice || 0);
    const stringSubTotal = formatIntToMoney(productSubTotal);
    const stringTotal = formatIntToMoney(productTotal * productObject.quantity);
    const optionStringArray = _.split(getProductString(productObject, '\n', false), '\n');
    request += builder.createTextElement({ ...defaultFormat, emphasis: true, data: `${name}:` });
    const isLongerThanMax = `${name}: ${stringTotal}`.length > MAX_CHAR_LENGTH;
    if (isLongerThanMax) {
      request += builder.createTextElement({ ...defaultFormat, data: '\n' });
    }
    const spacesNum = isLongerThanMax
      ? MAX_CHAR_LENGTH - stringTotal.length
      : MAX_CHAR_LENGTH - name.length - stringTotal.length;
    const whiteSpaces = getCharStringFromNum(spacesNum);
    request += builder.createTextElement({ ...defaultFormat, data: whiteSpaces });
    request += builder.createTextElement({ ...defaultFormat, invert: true, data: stringTotal });
    request += builder.createTextElement({
      ...defaultFormat,
      data: `\n${productObject.quantity} x ${stringSubTotal}\n`,
    });
    optionStringArray.forEach(it => {
      if (it) {
        request += builder.createTextElement({ ...defaultFormat, data: `  + ${it}\n` });
      }
    });
  });
  request += builder.createTextElement({
    ...defaultFormat,
    underline: true,
    data: `${getCharStringFromNum(MAX_CHAR_LENGTH, ' ')}\n`,
  });
  request += builder.createTextElement({ ...defaultFormat, data: '\n' });
  /// /////// PRODUCTOS FIN ///////////

  /// /////// TOTALES ///////////

  const totalString = `${formatIntToMoney(order.total)}`;
  const widthTotal = 2;
  const totalSingle = 'Total:';
  const spaces = getCharStringFromNum(MAX_CHAR_LENGTH / widthTotal - totalSingle.length - totalString.length);
  console.log(totalSingle + spaces + totalString);
  request += builder.createTextElement({
    emphasis: true,
    width: widthTotal,
    data: `${totalSingle + spaces + totalString}\n\n`,
  });

  request += builder.createTextElement({
    emphasis: true,
    width: 1,
    height: 1,
    data: `Metodo Pago: ${order.paymentType}\n`,
  });
  request += builder.createTextElement({
    emphasis: true,
    width: 1,
    height: 1,
    data: `Pago: ${formatIntToMoney(order.amountPosPayed)}\n`,
  });
  request += builder.createTextElement({
    emphasis: true,
    width: 1,
    height: 1,
    data: `Cambio: ${formatIntToMoney(order.change)}\n`,
  });

  request += builder.createTextElement({ ...defaultFormat, data: '\n' });
  request += builder.createTextElement({ ...defaultFormat, data: `${getCharStringFromNum(MAX_CHAR_LENGTH, ' ')}\n` });

  /// /////// TOTALES FIN ///////////

  const event = new Date();
  const fecha = event.toLocaleTimeString('ES-mx', { month: 'short', day: 'numeric', year: 'numeric' });

  request += builder.createAlignmentElement({ position: 'center' });
  request += builder.createTextElement({ ...defaultFormat, emphasis: true, data: `more life,\n` });
  request += builder.createTextElement({ ...defaultFormat, emphasis: true, data: `more beats,\n` });

  request += builder.createTextElement({ ...defaultFormat, width: 1, data: `${fecha}\n` });

  request += builder.createAlignmentElement({ position: 'left' });

  request += builder.createCutPaperElement({ feed: true });

  sendMessageToPrinter(request, ipName);
};
