import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Radio, Table, Popconfirm } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { PageHeader } from '../page-headers/page-headers';
import { Main, TableWrapper, TopToolBox } from '../../container/styled';
import { Button } from '../buttons/buttons';
import { Cards } from '../cards/frame/cards-frame';
import { orderFilter } from '../../redux/orders/actionCreator';
import { dateFromObjectId, getFormatedSecondsToTime, fetchPrinterConfig } from '../../utility/utility';
// import { printOrderQZ } from '../../utility/printerUtilsQZ';
// import { printOrder } from '../../utility/printerUtils';

const OrdersTable = ({ pageNumber, onChangePageNumber, onDeleteOrder, onRePrintOrder }) => {
  const { filterOrders, orders, total } = useSelector(state => {
    return {
      filterOrders: state.orders.filterOrders,
      orders: state.orders.orders,
      total: state.orders.totalResults,
    };
  });
  const dispatch = useDispatch();

  const [state, setState] = useState({
    notData: filterOrders,
    item: orders,
    selectedRowKeys: [],
  });

  const [ipName, setIpName] = useState('');

  const fetchPrinterConfiguration = async () => {
    const ipNameScope = await fetchPrinterConfig();
    setIpName(ipNameScope);
  };

  useEffect(() => {
    if (!ipName) {
      fetchPrinterConfiguration();
    }
  }, [ipName]);

  const { item, selectedRowKeys } = state;
  const filterKey = ['Entregada', 'Finalizada', 'Pendiente'];

  const handleOnChangePage = params => {
    onChangePageNumber(params);
  };

  useEffect(() => {
    if (orders) {
      setState({
        item: orders,
        selectedRowKeys,
      });
    }
  }, [orders, selectedRowKeys]);

  const handleChangeForFilter = e => {
    dispatch(orderFilter('statusOrder', e.target.value));
  };

  const handleOnDeleteOrder = orderId => {
    onDeleteOrder(orderId);
  };

  const handleOnRePrintOrder = orderId => {
    onRePrintOrder(orderId);
  };

  const dataSource = [];
  if (orders.length) {
    let iteareProp = orders;
    if (filterOrders?.length > 0) iteareProp = filterOrders;
    iteareProp.map((order, key) => {
      const {
        statusOrder,
        id,
        customerName,
        amount,
        subtotal,
        total,
        statusPay,
        createdBy,
        paymentType,
        statusCorte,
        memberCardId,
        memberCardAmount,
        timeElapsed,
      } = order;

      return dataSource.push({
        key: key + 1,
        id: <span className="order-id">{id.substring(8)}</span>,
        customer: <span className="customer-name">{customerName}</span>,
        createdBy: <span className="customer-name">{createdBy?.name}</span>,
        timeElapsed: <span>{getFormatedSecondsToTime(timeElapsed)}</span>,
        memberCardId: <span className="customer-name">{memberCardId?.name}</span>,
        statusOrder: (
          <span
            className={`status ${
              statusOrder === 'Finalizada' ? 'Success' : statusOrder === 'Pendiente' ? 'warning' : 'error'
            }`}
          >
            {statusOrder}
          </span>
        ),
        statusCorte: (
          <span
            className={`status ${
              statusCorte === 'Finalizado' ? 'Success' : statusCorte === 'Pendiente' ? 'warning' : 'error'
            }`}
          >
            {statusCorte}
          </span>
        ),
        statusPay: (
          <span
            className={`status ${
              statusPay === 'Pagada' ? 'Success' : statusOrder === 'Por Pagar' ? 'warning' : 'error'
            }`}
          >
            {statusPay}
          </span>
        ),
        paymentType: (
          <span
            className={`status ${
              paymentType === 'Efectivo' ? 'info' : paymentType === 'Tarjeta' ? 'Success' : 'error'
            }`}
          >
            {paymentType || 'Por Pagar'}
          </span>
        ),

        amount: <span className="ordered-amount">${amount}</span>,
        subtotal: <span className="ordered-amount">${subtotal}</span>,
        memberCardAmount: <span className="ordered-amount">${memberCardAmount}</span>,
        total: <span className="ordered-amount">${total}</span>,
        date: <span className="ordered-date">{dateFromObjectId(id)}</span>,
        action: (
          <div className="table-actions">
            <>
              <Popconfirm
                title="¿Seguro que quieres borrar esta orden?"
                onConfirm={() => {
                  if (statusOrder === 'Finalizada' && statusPay === 'Pagada') {
                    const confirm = window.confirm('Estás eliminando una orden finalizada. ¿Estás seguro?');
                    if (!confirm) return;
                  }
                  handleOnDeleteOrder(id);
                }}
                okText="Si"
                cancelText="No"
              >
                <Button className="btn-icon" type="danger" shape="circle">
                  <FeatherIcon icon="trash-2" size={16} />
                </Button>
              </Popconfirm>
              <Popconfirm
                title="¿Deseas Reimprimir?"
                onConfirm={() => {
                  handleOnRePrintOrder(id);
                  // printOrderQZ(order, ipName);
                }}
                okText="Si"
                cancelText="No"
              >
                <Button className="btn-icon" type="success" shape="circle">
                  <FeatherIcon icon="printer" size={16} />
                </Button>
              </Popconfirm>
            </>
          </div>
        ),
      });
    });
  }

  const columns = [
    {
      title: 'Id Orden',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Cliente',
      dataIndex: 'customer',
      key: 'customer',
    },
    {
      title: 'Nombre Tarjeta',
      dataIndex: 'memberCardId',
      key: 'memberCardId',
    },
    {
      title: 'Creada Por',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
    {
      title: 'Tiempo',
      dataIndex: 'timeElapsed',
      key: 'timeElapsed',
    },
    {
      title: 'Estatus',
      dataIndex: 'statusOrder',
      key: 'statusOrder',
    },
    {
      title: 'Estatus Corte',
      dataIndex: 'statusCorte',
      key: 'statusCorte',
    },
    {
      title: 'Estatus Pago',
      dataIndex: 'statusPay',
      key: 'statusPay',
    },
    {
      title: 'Método de Pago',
      dataIndex: 'paymentType',
      key: 'paymentType',
    },
    {
      title: 'Monto',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Subtotal',
      dataIndex: 'subtotal',
      key: 'subtotal',
    },
    {
      title: 'Monto Tarjeta Plus',
      dataIndex: 'memberCardAmount',
      key: 'memberCardAmount',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  return (
    <>
      <PageHeader ghost title="Orders" buttons={[<div key="1" className="page-header-actions" />]} />
      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={24}>
              <TopToolBox>
                <Row gutter={15} className="justify-content-left">
                  <Col xxl={14} lg={22} xs={24}>
                    <div className="table-toolbox-menu">
                      <span className="toolbox-menu-title"> Estatus:</span>
                      <Radio.Group onChange={handleChangeForFilter} defaultValue="">
                        <Radio.Button value="">Todos</Radio.Button>
                        {item.length &&
                          [...new Set(filterKey)].map(value => {
                            return (
                              <Radio.Button key={value} value={value}>
                                {value}
                              </Radio.Button>
                            );
                          })}
                      </Radio.Group>
                    </div>
                  </Col>
                </Row>
              </TopToolBox>
            </Col>
          </Row>
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-order table-responsive">
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  pagination={{
                    current: pageNumber,
                    pageSize: 25,
                    onChange: handleOnChangePage,
                    total,
                  }}
                />
              </TableWrapper>
            </Col>
          </Row>
        </Cards>
      </Main>
    </>
  );
};

OrdersTable.propTypes = {
  pageNumber: PropTypes.number,
  onChangePageNumber: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onRePrintOrder: PropTypes.func,
};
export default OrdersTable;
