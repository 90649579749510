import moment from 'moment-timezone';
import _ from 'lodash';
import { DataService } from '../config/dataService/dataService';
import { API } from '../config/api';
/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

const getDateFromId = objectId => {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

const getDifferenceBetween = (baseDate, aimDate) => {
  return moment(baseDate).diff(moment(aimDate), 'seconds');
};

const getFormatedSecondsToTime = (seconds = 0) => {
  return moment.utc(seconds * 1000).format('HH:mm:ss');
};
const dateFromObjectId = objectId => {
  const date = getDateFromId(objectId);
  return moment(date)
    .tz('America/Mazatlan')
    .format('MMM Do YY - h:mm:ss a');
};

const dateInHoursFromId = objectId => {
  const date = getDateFromId(objectId);
  return moment(date)
    .tz('America/Mazatlan')
    .format('h:mm:ss a');
};

const calculateTimeElapsed = id => {
  const difference = getDifferenceBetween(new Date(), getDateFromId(id));
  return getFormatedSecondsToTime(difference < 0 ? 0 : difference);
};

export {
  ellipsis,
  dateFromObjectId,
  dateInHoursFromId,
  getDateFromId,
  getDifferenceBetween,
  getFormatedSecondsToTime,
  calculateTimeElapsed,
};

export const getProductString = (product = {}, separator = ' / ', includeName = true) => {
  const name = _.get(product, 'productId.name', '');

  const options = product.options || [];

  const optionsNames = options.map(option => {
    const optionSelected = option.optionSelected || [];
    const optionSelectedList = optionSelected.map(selected => selected.title || '');
    return optionSelectedList.join('|');
  });
  const concatName = includeName ? [name] : [];
  let formattedName = concatName.concat(optionsNames).join(separator);
  const optionsCounter = product.optionsCounter || [];
  const counterName = optionsCounter.map(optionCount => {
    const optionsCount = (optionCount?.optionCounterQuantity || []).map(optionActive => {
      return `${optionActive?.optionCountId?.title} (${optionActive.optionQuantity})`;
    });
    return optionsCount.join(separator);
  });
  formattedName += counterName ? `${separator}${counterName}` : '';
  return formattedName;
};

export const calculateOptions = (optionsPos = [], optionsCounterPos = [], productOptions = []) => {
  const OPTIONS_TYPES = {
    COUNTER: 'COUNTER',
    OPTION: 'OPTION',
    BOOLEAN: 'BOOLEAN',
  };
  const optionsHash = _.keyBy(optionsPos, 'optionId.id');
  const optionsCounterHash = _.keyBy(optionsCounterPos, 'optionId.id');
  let extraPrice = 0;
  // eslint-disable-next-line
  for (const dbOption of productOptions) {
    let optionPosMatch = _.get(optionsHash, `${dbOption.id}`);

    if (!optionPosMatch) {
      // Si no se encontro en options buscamos dentro de options counter en caso que venga ahi
      optionPosMatch = _.get(optionsCounterHash, `${dbOption.id}`);
      if (!optionPosMatch) {
        continue;
      }
    }

    let optionSelect = {};
    switch (dbOption.type) {
      case OPTIONS_TYPES.BOOLEAN:
        {
          if (optionPosMatch.optionSelected.length !== 1) continue;
          const hashOptionToSelect = _.keyBy(dbOption.options, '_id');
          optionSelect = hashOptionToSelect[_.get(optionPosMatch, 'optionSelected[0]._id')];
          if (!optionSelect) {
            continue;
          }
        }
        extraPrice += optionSelect.extraPrice || 0;
        break;
      case OPTIONS_TYPES.OPTION:
        break;
      case OPTIONS_TYPES.COUNTER:
        {
          if (!optionPosMatch.optionCounterQuantity.length) continue;

          const hashOptionToSelect = _.keyBy(dbOption.options, '_id');

          // eslint-disable-next-line
          optionPosMatch.optionCounterQuantity.forEach(optionActiveCount => {
            const optionToCount = _.get(hashOptionToSelect, `[${optionActiveCount?.optionCountId?._id}]`, {});
            if (_.isEmpty(optionToCount)) {
              return;
            }

            const extraPriceCount = optionToCount.extraPrice * optionActiveCount.optionQuantity;

            extraPrice += extraPriceCount;
          });
        }
        break;
      default:
        break;
    }
  }
  return extraPrice;
};

export const fetchPrinterConfig = async () => {
  let ipName = '127.0.0.1';
  console.log('calling fetch printer');
  try {
    const payload = await DataService.get(`${API.pos.config}/`);
    const configurationPayload = _.get(payload, 'data.results[0]', { ipName: '' });
    ipName = configurationPayload?.ipName;
  } catch (e) {
    console.log('no pudo obtener información impresora');
  }
  return ipName;
};
