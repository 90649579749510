const certificate = `-----BEGIN CERTIFICATE-----
MIID5zCCAs+gAwIBAgIULIxPWCtQx/FPGVD6sRGZMbSi08EwDQYJKoZIhvcNAQEL
BQAwgYIxCzAJBgNVBAYTAk1YMRAwDgYDVQQIDAdTaW5hbG9hMRIwEAYDVQQHDAlH
dWFtdWNoaWwxFDASBgNVBAoMC0xhdGlkbyBDYWZlMQ8wDQYDVQQDDAZMYXRpZG8x
JjAkBgkqhkiG9w0BCQEWF2NvbnRhY3RvQGxhdGlkb2NhZmUuY29tMB4XDTI0MTEx
NzA2MjE1MloXDTI2MTExNzA2MjE1MlowgYIxCzAJBgNVBAYTAk1YMRAwDgYDVQQI
DAdTaW5hbG9hMRIwEAYDVQQHDAlHdWFtdWNoaWwxFDASBgNVBAoMC0xhdGlkbyBD
YWZlMQ8wDQYDVQQDDAZMYXRpZG8xJjAkBgkqhkiG9w0BCQEWF2NvbnRhY3RvQGxh
dGlkb2NhZmUuY29tMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuAe+
F44QSYR8y26NLV2fndP/Efz7go/7mzi5jFjuQ+gkjuyOnw3eYOOWMeLJcuTn9u5Q
HBFIa8baGe3X0LKoQJnFkwRxwpFnu2kkigQzfd5eabRObOeWCjGmdaSoqRzztxU0
u55DqQEhVHN0rtcQlipXksPZnPvYzNLpR/YHSUHD2s4iJyTjogLVQAtpojsWEVUT
aG61Au+BWTM+NUIG07dJaFL1XlYPAsma4+pWLMaEvz+Ah9j4De4yUkBa/a1MNwhY
bPP8QJofmRy/TeC3YPWnH6nkqOdLMvtdzqOc+PcvlwoiBo4jnUFfQb/Bav64pEh8
O8P6omt6azTpFzS29wIDAQABo1MwUTAdBgNVHQ4EFgQUD3gGyidx8AhJQW0Kgc3M
L4gvP9IwHwYDVR0jBBgwFoAUD3gGyidx8AhJQW0Kgc3ML4gvP9IwDwYDVR0TAQH/
BAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEAr8UB2wYRU4KwjQ6mzqOVfivfxRYe
0QJsALQ4LZYF3IB/LEq0k1O7EVsaaYfWesvKl/P1IahN87/dY21PYjfZY6GqoGb0
GjTgrIEddeN13RcvEaYCxYvCYrjCcfYwLKxE5GkP8fE0G0/9rsswyUl71lozYpGE
fPaaLfJRfr2mmENlToAm5WgnGcw/MsFoeHHQoUwDUZ2dJDEyEAkdUEXtPUXVmgeg
ktUcVdFX3X1ioQRFL40qfwwgyORLSeRnNXHhTv9LvgKlrhbdQ9OIR7FO4kYCqym0
fmKkGsIt6ETNJRJ/y4tMbFe63zw3e0JKzU1QiDlLQfyHYymAcRdaicv5yA==
-----END CERTIFICATE-----

`;

export default certificate;
