const privateKey = `-----BEGIN ENCRYPTED PRIVATE KEY-----
MIIFNTBfBgkqhkiG9w0BBQ0wUjAxBgkqhkiG9w0BBQwwJAQQd8RlN6rcqu2XgzjL
e7jgnQICCAAwDAYIKoZIhvcNAgkFADAdBglghkgBZQMEASoEEAkF4ZOlOll9uaeh
xwcN9boEggTQpfwwp4YHW6QDffMmkOAc8jHsaVVBKJ6JAyKR56k12f7MIibAxDEj
QglvqIjseu7582L0COfgFeskphnfs7k+5GLiW/RevIUjLI8dYpMZOGFg5Zdd/iye
6W0rs7a0TJKwu8b6sszJcG5oDstVeB3ze32nG28HxGiyGZ6UDZJThWvwrcOqEkEh
zyudskqdbq3B/iNsmThgbc3YvMinyLBeLpqXHqV3Ii0ab2Sn7PFU4WP4WNSgu18t
X8FQjnTxK/LDtd8La6h99HVy0368cNczx6Qv/y5DwYsuV6n6U0hZcLwHRl2HMOi/
4jpo/u1qN5+K3+PjXIL4tq3K+fX7MEsPWobMg0s1iPM67wnzCVmX2c/ehyo83nnE
/m4YxmOkEc5MyhmhbCDCCKM8G4DTQqEotINHR3Hei5noCykwtL6utbz8wJnAmsTG
lMve+SLxAYml6bU2tSSyz3uDv+6s0DRCRCwK7oWLhXYmCQui+AOqeL99eZXcKH/Z
QH66tZj0u1HT56I4CEbri9bXxypZzD339OdV1EZII9q7MVBYvktlxpO2sHhaai+2
qM2FXgeIsk615QtUsxnABSra+aCT0czQfvrcf/MOUOuemc3dIpOB2HKU0nkGg6aG
XbxQTayMyFJdwzjDu917dM1kGzZPmVgej/z9C/agfDGO0exGQnsWpCn9/zcKcu/P
VVa3cs+jAdZ6Tm1Ao845gLT0dtSJQtgf7DlaomVJU/A0nerwHQJxlLLwuXjhh0UA
OBPsObwJ0f3EE204UqICWlcyiXeWXe0kTOh5gIy3WgA77ibV3SOxDRx0MhrQuoMi
0smQhLDGYZUeuKZThux1Nn9RSCB7+LhlDakdeLoyz6hEWJbt4QhLTSe6QgkbeWJM
eCWEuYgNMKKIUAuI1Z3L2QcaU1lRhoiYjIvHcAm1g95NtfouZgyNCHqE8SPDhJLL
/z9qGybIMO3RkvFDOfc/XjAsoTQFreDXMWYvB0LpTZ38GHVRNLdc4WroeU3h7y5t
rX3YwV7Y4G5QMOCuVlHZ9Qm3leHw0JPG1NTA2/kRsccx4xwQoOd/TrxrR7jP6+KL
HVUzyEmd3VlPbKsME3IUYgghjzyepPMdpk+gA98y/BJkVfEkbHicRYg2Aa3skrYA
NvjumEAceP1qNx79ZFahgZsOrsi51+jYvpD7S16L7n0j1NicwGUXnY5PC1bD7uOe
Tq5qoJPtEZErqsM3j1LDMCU9mkbf5vk9HThJadNHhMQKH2vA954cARWJJZaGsD/A
ThY5ykqn9geNi9E8+CRHeJ5QRGoSs+beveu8FZQ77t3k4NvIbXtWujTsUGaPn3fl
mPEgUxl9VyIY+MFuWPsS0FijRIRrpJQCxWJGbdsLNkdi6Gn5PfYN4EVWdcxhVh3S
dqDolRSJNjH89q8LCsFwJUuQMmCFJ0WU40N4hv+2mHX45WcOrBZQlScOfjgsIM76
b4D1Xw2sQunR5E9l0yf3Y7MMWjwE/KkcYH1BbXmfwI/3ySw7nNA7FKd7O2nw3Xct
auOSTyx7dtAMPDprjZMVYuGo7zOaO3MBcRmgBdTD8kuSSF0IhNSLqZ9KiM2/BcPc
t5IwkfRYOTlPrEEud6TBouHdkW12q/m2xR8cwNTyqOcFckfAUQv2niQ=
-----END ENCRYPTED PRIVATE KEY-----`;

export default privateKey;
