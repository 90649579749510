import qz from 'qz-tray';
import { KEYUTIL, KJUR, stob64, hextorstr } from 'jsrsasign';
import { latidoLogoBase64 } from './latidoBase64';
import privateKey from '../static/cert/private';
// import certificate from '../static/cert/certificate';

const MAX_CHAR_LENGTH = 32;

// Formatear monto como dinero
const formatIntToMoney = amount => {
  return `$${(Math.round(amount * 100) / 100).toFixed(2)}`;
};

// Crear espacios en blanco
const getCharStringFromNum = (n = 0, char = ' ') => {
  return char.repeat(n);
};

// Configuración de la impresora
const getPrinterConfig = () => {
  return qz.configs.create('POS58 Printer'); // Cambia 'POS58 Printer' por el nombre exacto de tu impresora
};

// Función principal de impresión
export const printOrderQZ = async (order, ipName) => {
  console.log('Imprimiendo...');
  console.log(ipName);
  try {
    // Conectar con QZ Tray
    if (!qz.websocket.isActive()) {
      try {
        qz.security.setCertificatePromise(function(resolve, reject) {
          // Preferred method - from server
          //        fetch("assets/signing/digital-certificate.txt", {cache: 'no-store', headers: {'Content-Type': 'text/plain'}})
          //          .then(function(data) { data.ok ? resolve(data.text()) : reject(data.text()); });

          // Alternate method 1 - anonymous
          //        resolve();  // remove this line in live environment

          // Alternate method 2 - direct
          resolve(`-----BEGIN CERTIFICATE-----
  MIID5zCCAs+gAwIBAgIULIxPWCtQx/FPGVD6sRGZMbSi08EwDQYJKoZIhvcNAQEL
  BQAwgYIxCzAJBgNVBAYTAk1YMRAwDgYDVQQIDAdTaW5hbG9hMRIwEAYDVQQHDAlH
  dWFtdWNoaWwxFDASBgNVBAoMC0xhdGlkbyBDYWZlMQ8wDQYDVQQDDAZMYXRpZG8x
  JjAkBgkqhkiG9w0BCQEWF2NvbnRhY3RvQGxhdGlkb2NhZmUuY29tMB4XDTI0MTEx
  NzA2MjE1MloXDTI2MTExNzA2MjE1MlowgYIxCzAJBgNVBAYTAk1YMRAwDgYDVQQI
  DAdTaW5hbG9hMRIwEAYDVQQHDAlHdWFtdWNoaWwxFDASBgNVBAoMC0xhdGlkbyBD
  YWZlMQ8wDQYDVQQDDAZMYXRpZG8xJjAkBgkqhkiG9w0BCQEWF2NvbnRhY3RvQGxh
  dGlkb2NhZmUuY29tMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuAe+
  F44QSYR8y26NLV2fndP/Efz7go/7mzi5jFjuQ+gkjuyOnw3eYOOWMeLJcuTn9u5Q
  HBFIa8baGe3X0LKoQJnFkwRxwpFnu2kkigQzfd5eabRObOeWCjGmdaSoqRzztxU0
  u55DqQEhVHN0rtcQlipXksPZnPvYzNLpR/YHSUHD2s4iJyTjogLVQAtpojsWEVUT
  aG61Au+BWTM+NUIG07dJaFL1XlYPAsma4+pWLMaEvz+Ah9j4De4yUkBa/a1MNwhY
  bPP8QJofmRy/TeC3YPWnH6nkqOdLMvtdzqOc+PcvlwoiBo4jnUFfQb/Bav64pEh8
  O8P6omt6azTpFzS29wIDAQABo1MwUTAdBgNVHQ4EFgQUD3gGyidx8AhJQW0Kgc3M
  L4gvP9IwHwYDVR0jBBgwFoAUD3gGyidx8AhJQW0Kgc3ML4gvP9IwDwYDVR0TAQH/
  BAUwAwEB/zANBgkqhkiG9w0BAQsFAAOCAQEAr8UB2wYRU4KwjQ6mzqOVfivfxRYe
  0QJsALQ4LZYF3IB/LEq0k1O7EVsaaYfWesvKl/P1IahN87/dY21PYjfZY6GqoGb0
  GjTgrIEddeN13RcvEaYCxYvCYrjCcfYwLKxE5GkP8fE0G0/9rsswyUl71lozYpGE
  fPaaLfJRfr2mmENlToAm5WgnGcw/MsFoeHHQoUwDUZ2dJDEyEAkdUEXtPUXVmgeg
  ktUcVdFX3X1ioQRFL40qfwwgyORLSeRnNXHhTv9LvgKlrhbdQ9OIR7FO4kYCqym0
  fmKkGsIt6ETNJRJ/y4tMbFe63zw3e0JKzU1QiDlLQfyHYymAcRdaicv5yA==
  -----END CERTIFICATE-----`);
        });
        qz.security.setSignatureAlgorithm('SHA512'); // Since 2.1
        qz.security.setSignaturePromise(function(toSign) {
          return function(resolve, reject) {
            try {
              const pk = KEYUTIL.getKey(privateKey, 'latido');
              const sig = new KJUR.crypto.Signature({ alg: 'SHA512withRSA' }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
              sig.init(pk);
              sig.updateString(toSign);
              const hex = sig.sign();
              console.log('Certificado cargado correctamente');
              resolve(stob64(hextorstr(hex)));
            } catch (err) {
              console.error(err);
              reject(err);
            }
          };
        });
        await qz.websocket.connect({
          host: ipName,
          port: { secure: [8181] }, // Puerto seguro
          usingSecure: true,
        });
      } catch (error) {
        console.error('Error al conectar con QZ Tray:', error);
        await qz.websocket.connect({
          host: 'localhost',
          port: { secure: [8181] }, // Puerto seguro
          usingSecure: true,
        });
      }
      console.log('Conectado a QZ Tray');
    }

    const config = getPrinterConfig();

    // Crear contenido del ticket
    let data = '';

    // Cabecera
    data += `Usuario: ${order.createdBy.name}\n`;
    data += `Cliente: ${order.customerName}\n\n`;

    // Productos
    order.products.forEach(product => {
      const name = product?.productId?.name || 'Producto';
      const quantity = product.quantity || 1;
      const price = product?.productId?.price || 0;
      const subtotal = formatIntToMoney(price * quantity);

      // Producto principal
      data += `${name}\n`;
      data += `Cantidad: ${quantity} x ${formatIntToMoney(price)}\n`;
      data += `Total Producto: ${subtotal}\n`;

      // Opciones seleccionadas
      product.options.forEach(optionGroup => {
        optionGroup.optionSelected.forEach(option => {
          data += `  + ${option.title}\n`;
        });
      });

      // Opciones de contador (si aplica)
      if (product.optionsCounter?.length > 0) {
        product.optionsCounter.forEach(counter => {
          data += `  + ${counter.title}\n`;
        });
      }

      data += '\n';
    });

    // Separador
    data += `${getCharStringFromNum(MAX_CHAR_LENGTH, '-')}\n`;

    // Totales
    const total = formatIntToMoney(order.total);
    const totalSpaces = getCharStringFromNum(MAX_CHAR_LENGTH - 6 - total.length);
    data += `Total:${totalSpaces}${total}\n`;

    const paymentTypeSpaces = getCharStringFromNum(MAX_CHAR_LENGTH - 15 - order.paymentType.length);
    data += `Método Pago:${paymentTypeSpaces}${order.paymentType}\n`;

    const payment = formatIntToMoney(order.amountPosPayed);
    const paymentSpaces = getCharStringFromNum(MAX_CHAR_LENGTH - 5 - payment.length);
    data += `Pago:${paymentSpaces}${payment}\n`;

    const change = formatIntToMoney(order.change);
    const changeSpaces = getCharStringFromNum(MAX_CHAR_LENGTH - 7 - change.length);
    data += `Cambio:${changeSpaces}${change}\n\n`;

    // Pie del ticket
    data += `more life, more beats\n`;

    const fecha = new Date().toLocaleDateString('ES-mx', { month: 'short', day: 'numeric', year: 'numeric' });
    data += `Fecha: ${fecha}\n`;

    // Corte del papel
    // data += '\x1B\x64\x03'; // Comando ESC/POS para corte de papel

    // Enviar impresión

    await qz.print(config, [
      {
        type: 'raw',
        format: 'image',
        flavor: 'base64',
        data: latidoLogoBase64,
      },
      { type: 'raw', format: 'plain', data },
      // eslint-disable-next-line no-useless-concat
      '\x1B' + '\x69', // cut paper
    ]);
    console.log('Impresión completada');
  } catch (error) {
    console.error('Error al imprimir:', error);
  }
};
